<template>
    <div class="widget__item widget__testing dashboard__block" v-if="(data.is_admin || data.configured) && this.data.is_connected && isActive">
        <div class="testing__header">
            <h2 class="h2 testing__title">Тестирование</h2>
            <router-link v-if="data.is_admin && this.data.is_connected" tag="a" to="/testing/settings" class="testing__settings">
                <i class="fas fa-cog dashboard__fa-icon testing__icon"></i>
            </router-link>
        </div>

        <!-- Перед тестированием -->
        <div class="testing__body" v-if="!data.is_admin && data.configured" v-show="data.user_info.attempts === 3">
            <img width="60" height="60" src="../../assets/img/favicons/doc_not_ready.svg" alt="" class="testing__doc-icon">
            <span class="testing__text">Тестирование не пройдено.</span>
            <span class="testing__text">Количество попыток переаттестации исчерпаны.</span>
        </div>

        <!-- Перед тестированием -->
        <div class="testing__body" v-if="!data.is_admin && data.configured" v-show="data.user_info.study">
            <img width="60" height="60" src="../../assets/img/favicons/doc_not_ready.svg" alt="" class="testing__doc-icon">
            <span class="testing__text">Вы не готовы к тестированию.</span>
            <span class="testing__text">Изучите материалы, необходимые для прохождения тестирования.</span>
        </div>

        <!-- Перед тестированием -->
        <div class="testing__body" v-if="!data.is_admin && data.configured && data.user_info.attempts < 3" v-show="data.user_info.repeat">
            <img width="60" height="60" src="../../assets/img/favicons/doc_not_ready.svg" alt="" class="testing__doc-icon">
            <span class="testing__text">Вы не готовы к переаттестации.</span>
            <span class="testing__text">Изучите материалы, необходимые для прохождения тестирования.</span>
        </div>

        <!-- Перед тестированием -->
        <div class="testing__body" v-if="!data.is_admin && data.configured && data.user_info.attempts < 3" v-show="data.user_info.start">
            <img width="60" height="60" src="../../assets/img/favicons/doc_not_ready.svg" alt="" class="testing__doc-icon">
            <span class="testing__text">Вы готовы к тестированию.</span>
        </div>

        <!-- Повторное тестирование -->
        <div class="testing__body" v-if="!data.is_admin && data.configured && data.user_info.attempts < 3" v-show="data.user_info.timer">
            <vue-easy-pie-chart class="js-pie-chart-vue testing__timer" :size="194" :scale-length="0" :line-width="11"
                                bar-color="#fcc142" track-color="#e9e9e9" :percent="percent">
                <span class="testing__timer-body">
                    <span class="testing__time">{{minutes}}:{{seconds}}</span>
                    <span class="testing__desc">до следующей <br/> попытки</span>
                </span>
            </vue-easy-pie-chart>
        </div>

        <!-- Тестирование пройдено -->
        <div class="testing__body" v-if="!data.is_admin && data.configured" v-show="data.user_info.end">
            <img width="60" height="60" src="../../assets/img/favicons/testing-completed.svg" alt=""
                 class="testing__angle-icon">
            <span class="testing__text">Вы успешно справились со всеми вопросами</span>
        </div>

        <!-- Результаты тестирования -->
        <div class="testing__body" v-if="data.is_admin && data.configured">
            <vue-easy-pie-chart class="js-pie-chart-vue testing__result" :size="194" :scale-length="0" :line-width="11"
                                bar-color="#fcc142" track-color="#e9e9e9" :percent="percent">
                <span class="testing__result-body">
                    <div class="testing__result-count">
                        <span class="testing__result-ready">{{data.admin_info.count_ready}}</span>
                        из
                        <span class="testing__result-total ">{{data.admin_info.total}}</span>
                    </div>
                    <span class="testing__desc">сотрудников <br/> готовы к работе</span>
                </span>
            </vue-easy-pie-chart>
        </div>

        <!-- Тестирование не настроено -->
        <div class="testing__body" v-if="data.is_admin  && !data.configured">
            <img width="60" height="60" src="../../assets/img/favicons/doc_not_ready.svg" alt="" class="testing__angle-icon">
            <span class="testing__text">Тестирование не настроено</span>
        </div>

        <div class="testing__footer">
            <router-link v-if="data.is_admin  && !data.configured" tag="a" to="/testing/settings" class="v-btn">
                Настроить
            </router-link>
            <router-link v-if="data.is_admin  && data.configured" tag="a" to="/testing/results" class="testing__link">
                Результаты тестирования
            </router-link>
            <router-link v-if="!data.is_admin && data.configured"
                         v-show="data.user_info.study || data.user_info.repeat"
                         tag="a" to="/knowledge/?learn=1" class="testing__link">
                Открыть базу знаний
            </router-link>
            <router-link v-if="!data.is_admin && data.configured"
                         v-show="data.user_info.start"
                         tag="a" to="/testing" class="v-btn">
               Перейти к тестированию
            </router-link>
<!--            <div v-if="!data.is_admin && data.configured" v-show="data.user_info.timer" class="testing__text">-->
<!--                Осталось {{attempts}} попытки-->
<!--            </div>-->
            <a v-if="!data.is_admin && data.configured" v-show="data.user_info.end" href="javascript:void(0)" @click="hideStep"
               class="testing__hide"><i class="fas fa-eye-slash testing__hide-icon dashboard__fa-icon"></i> Скрыть</a>
        </div>

    </div>
</template>

<script>
    import session from '@/api/session';
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css';

    export default {
        name: 'TestingWidget',
        components:{
            VueEasyPieChart : () => import('vue-easy-pie-chart')
        },
        data() {
            return {
                isActive: false,
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
                percent: 0,
                attempts: 0,
                data: {
                    is_connected: true,
                    is_admin: false,
                    configured: true,
                    admin_info: {
                        count_ready: 0,
                        total: 0
                    },
                    user_info: {
                        start: false,
                        end: false,
                        study: false,
                        timer: false,
                        time: null,
                        attempts: 0,
                        repeat: null,
                    }
                }
            }
        },
        created: function () {
            this.loadData();
        },
        mounted() {
            session
                .get('/api/v1/dashboard-widget/hide_step/')
                .then(response => {
                    this.isActive = response.data['is_active']
                })
                .catch(() => {
                })
        },
        methods: {
            hideStep() {
                session
                    .post('/api/v1/dashboard-widget/hide_step/')
                    .then(response => {
                        this.isActive = false;
                    })
                    .catch(() => {
                    })
            },
            loadData() {
                session
                .get('/api/v1/dashboard-widget/testing/')
                .then(response => {
                    this.data = response.data;
                    if (!this.data.is_admin && this.data.configured && this.data.user_info.timer) {
                        this.theTime();
                    }
                    if (this.data.is_admin && this.data.configured) {
                        this.percent = 100 * this.data.admin_info.count_ready / this.data.admin_info.total;
                    }
                    // if (this.data.is_admin && this.data.user_info.attempts === 3) {
                    //     this.percent = 100 * this.data.admin_info.count_ready / this.data.admin_info.total;
                    // }
                    // this.attempts = 3 - this.data.user_info.attempts
                })
                .catch(() => {
                })
            },
            theTime() {
                var
                    timer,
                    ctx = this,
                    endDate = new Date(this.data.user_info.time).getTime();

                timer = setInterval(function () {
                    timeBetweenDates(endDate);
                }, 1000);

                function timeBetweenDates(endDate) {
                    let difference = endDate - new Date().getTime();

                    if (difference <= 0) {
                        // Timer done
                        clearInterval(timer);
                        this.percent = 0;
                        this.loadData();
                    } else {

                        let
                            seconds = Math.floor(difference / 1000),
                            minutes = Math.floor(seconds / 60),
                            hours = Math.floor(minutes / 60),
                            days = Math.floor(hours / 24);

                        hours %= 24;
                        minutes %= 60;
                        seconds %= 60;

                        ctx.days = days;
                        ctx.hours = hours;
                        ctx.minutes = minutes;
                        ctx.seconds = seconds;
                        ctx.percent = 100 * difference / 3600000;
                    }
                }
            },
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"


    .inner-text
        display: flex !important
        justify-content: center !important

    .widget__testing
        height: 380px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between

        .testing
            &__text
                font-size: 1rem
                text-align: center
                margin: 0 auto

            &__desc
                font-size: 1rem
                line-height: 18px

            &__header
                display: flex
                width: 100%
                justify-content: space-between
                align-items: center

            &__icon
                font-size: 1.25rem
                color: #e0e3f0

                &:hover
                    color: $gold

            &__body
                display: flex
                flex-direction: column
                align-items: center

            &__result-body,
            &__timer-body
                width: 100% !important
                display: flex
                flex-direction: column
                text-align: center
                justify-content: center
                line-height: 30px
                height: 100%

            &__result-count
                font:
                    size: 18px
                    weight: 600

            &__result-ready,
            &__time
                font:
                    size: 32px
                    weight: 600

            &__doc-icon,
            &__angle-icon
                height: 60px
                width: 60px
                margin-bottom: 15px

            &__link
                color: $link-main
                font-size: 1rem

            &__hide
                font-size: 1rem
                color: #AAAAAA

                &-icon
                    margin-right: 8px
                    font-size: 1.125rem

                &:hover
                    color: #aaaaaa
</style>
